import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/3d0ba6e8/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import "../scss/app.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "headings"
    }}><strong parentName="h2">{`Headings`}</strong></h2>
    <br />
    <Playground __position={1} __code={'<h1 class=\"h1\">Control the 1 Open Source Storage Platform for Kubernetes</h1>\n<h2 class=\"h2\">Control the 1 Open Source Storage Platform for Kubernetes</h2>\n<h3 class=\"h3\">Control the 1 Open Source Storage Platform for Kubernetes</h3>\n<h4 class=\"h4\">Control the 1 Open Source Storage Platform for Kubernetes</h4>\n<h5 class=\"h5\">Control the 1 Open Source Storage Platform for Kubernetes</h5>\n<h6 class=\"h6\">Control the 1 Open Source Storage Platform for Kubernetes</h6>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <h1 className="h1">Control the 1 Open Source Storage Platform for Kubernetes</h1>
  <h2 className="h2">Control the 1 Open Source Storage Platform for Kubernetes</h2>
  <h3 className="h3">Control the 1 Open Source Storage Platform for Kubernetes</h3>
  <h4 className="h4">Control the 1 Open Source Storage Platform for Kubernetes</h4>
  <h5 className="h5">Control the 1 Open Source Storage Platform for Kubernetes</h5>
  <h6 className="h6">Control the 1 Open Source Storage Platform for Kubernetes</h6>
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "paragraph"
    }}><strong parentName="h2">{`Paragraph`}</strong></h2>
    <br />
    <Playground __position={4} __code={'<p>Control the 1 Open Source Storage Platform for Kubernetes</p>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <p>Control the 1 Open Source Storage Platform for Kubernetes</p>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      